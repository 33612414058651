import React from 'react';
import {UserBadge, TextLabel} from '@/components';
import {PageGrid} from '@/homePage/components';
import styled from 'styled-components';
import {numberWithCommas} from '@/util';
import {useEntity} from '@/hooks';
import {propTypes} from './CommunityRanking.shared';
import noop from 'lodash/noop';

const CommunityRanking = ({userId, rank, iq, onClick = noop}) => {
  const url = useEntity('users', userId).url;
  return (
    <CommunityRanking.Row as="a" href={url} onClick={onClick}>
      <CommunityRanking.Rank>{rank}</CommunityRanking.Rank>
      <CommunityRanking.User>
        <UserBadge id={userId} avatarSize={60} />
      </CommunityRanking.User>
      <CommunityRanking.Iq>
        <TextLabel color="accent.main">{numberWithCommas(iq, {round: true})}</TextLabel>
      </CommunityRanking.Iq>
    </CommunityRanking.Row>
  );
};

CommunityRanking.propTypes = propTypes;

export default React.memo(CommunityRanking);

CommunityRanking.Row = styled(PageGrid)`
  color: inherit;
  align-items: center;
  height: 75px;
  border-bottom: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
  font-weight: 100;
`;

CommunityRanking.Rank = styled.div`
  grid-column: span 1;
  font-size: ${p => p.theme.fontSize.xSmallHeadline};
  text-align: center;
`;

CommunityRanking.User = styled.div`
  grid-column: 2 / span 5;
`;

CommunityRanking.Iq = styled.div`
  grid-column: 8 / span 1;
  text-align: right;
`;
