import React from 'react';
import {SizedImage, PlayButtonOverlay, LoadMoreButton} from '@/components';
import {PageGrid, PageGridCenter, PageGridFull, EditorialPlacement, SectionTitle, VideoListLogo} from '@/homePage/components';
import styled from 'styled-components';
import {useRedux} from './Videos.shared';
import {useTranslation} from 'react-i18next';

const Videos = () => {
  const {heroId, heroUrl, heroImageUrl, heroLogo, heroLogoUrl, videoListIds, onPlacementClick, onLoadMoreClick} = useRedux();
  const {t} = useTranslation();
  return (
    <PageGrid>
      <SectionTitle
        title={t('videos.title')}
        subtitle={t('videos.subtitle')}
      />
      <PageGridFull>
        <PageGrid noRowGap={true}>
          <Videos.HeroLogo>
            <VideoListLogo
              slug={heroLogo}
              url={heroLogoUrl}
              onClick={onPlacementClick}
            />
          </Videos.HeroLogo>
          <PageGridFull as="a" href={heroUrl} onClick={onPlacementClick}>
            <PlayButtonOverlay>
              <SizedImage
                src={heroImageUrl}
                aspectRatio={16 / 9}
                fitHeightAndRepeat={true} />
            </PlayButtonOverlay>
          </PageGridFull>
          <PageGridCenter>
            <EditorialPlacement videoListId={heroId} variant="watchHeroMobile" onClick={onPlacementClick} />
          </PageGridCenter>
        </PageGrid>
      </PageGridFull>
      {videoListIds.map(id => (
        <PageGridCenter key={id}>
          <EditorialPlacement
            videoListId={id}
            variant="watch"
            onClick={onPlacementClick}
          />
        </PageGridCenter>
      ))}
      <LoadMoreButton
        path="home.videoSection"
        container={Videos.LoadMore}
        onClick={onLoadMoreClick}
      />
    </PageGrid>
  );
};

export default Videos;

Videos.HeroLogo = styled(PageGridCenter)`
  margin-bottom: ${p => p.theme.space.half};
`;

Videos.LoadMore = styled(PageGridCenter)`
  text-align: center;
`;
