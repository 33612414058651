import React from 'react';
import {EditorialImage, DfpAd} from '@/components';
import {PageGrid, PageGridCenter, PageGridFull, EditorialPlacement} from '@/homePage/components';
import {useRedux} from './TopContent.shared';
import {useAd} from '@/hooks';

const BRANDED_CONTENT_INDEX = 1;

const TopContent = () => {
  const {hero, placements, heroEntity, onPlacementClick, showAds} = useRedux();
  const {state: adState} = useAd('mobile_home_promo_unit');
  const promoSlotRequestedOrFilled = adState !== 'empty';

  return (
    <PageGrid>
      <PageGridCenter>
        <EditorialPlacement
          placement={hero}
          variant="homeTopHero"
          onClick={onPlacementClick}
        />
      </PageGridCenter>
      <PageGridFull as="a" href={heroEntity.url} onClick={onPlacementClick}>
        <EditorialImage
          url={heroEntity[heroEntity.type === 'article' ? 'previewImage' : 'posterUrl']}
          playButton={heroEntity.type === 'video'}
          lazy={false}
        />
      </PageGridFull>
      {placements.map((placement, i) => (
        <PageGridCenter key={`${placement.schema}:${placement.id}`}>
          {showAds && i === BRANDED_CONTENT_INDEX && promoSlotRequestedOrFilled ?
            <DfpAd
              name={'mobile_home_promo_unit'}
              resizeIframe={true}
              lazy={false}
              refreshIntervalSeconds={null}
            /> :
            <EditorialPlacement placement={placement} onClick={onPlacementClick} />
          }
        </PageGridCenter>
      ))}
    </PageGrid>
  );
};

export default React.memo(TopContent);
