import React from 'react';
import {SizedImage, IconWithLabel, TextLabel} from '@/components';
import styled from 'styled-components';
import {abbreviateBigNumber} from '@/util';
import {overflowWithEllipsis} from '@/style';
import {propTypes} from './ChartSong.shared';
import Fire from '@/svg/fire.svg';
import Eye from '@/svg/eye.svg';
import {useEntity} from '@/hooks';

const ChartSong = ({itemId}) => {
  const song = useEntity('songs', itemId);
  return (
    <>
      <ChartSong.CoverAndTitleAndArtist>
        <ChartSong.Cover>
          <SizedImage
            src={song.songArtImageThumbnailUrl}
            width={60}
            height={60}
          />
        </ChartSong.Cover>
        <ChartSong.TitleAndArtist>
          <ChartSong.Title>{song.title}</ChartSong.Title>
          <ChartSong.Artist>{song.primaryArtistNames}</ChartSong.Artist>
          <TextLabel color="background.onVariant" as="div">Lyrics</TextLabel>
        </ChartSong.TitleAndArtist>
      </ChartSong.CoverAndTitleAndArtist>

      <ChartSong.Metadata>
        {song.stats.concurrents &&
          <ChartSong.Metadatum>
            {song.stats.hot &&
              <IconWithLabel color="accent.main" iconTop={-2} label={song.stats.concurrents}>
                <Fire />
              </IconWithLabel>
            }
          </ChartSong.Metadatum>
        }
        <ChartSong.Metadatum>
          <IconWithLabel label={abbreviateBigNumber(song.stats.pageviews)}>
            <Eye />
          </IconWithLabel>
        </ChartSong.Metadatum>
      </ChartSong.Metadata>
    </>
  );
};


ChartSong.propTypes = propTypes;

export default React.memo(ChartSong);

ChartSong.CoverAndTitleAndArtist = styled.div`
  grid-column: 2 / span 5;
  display: flex;
  align-items: center;
`;

ChartSong.Cover = styled.div`
  flex-shrink: 0;
`;

ChartSong.TitleAndArtist = styled.h3`
  margin-left: ${p => p.theme.space.xLarge};
  min-width: 0;
  line-height: ${p => p.theme.lineHeight.short};
`;

ChartSong.Title = styled.div`
  ${overflowWithEllipsis}
`;

ChartSong.Artist = styled.div`
  font-weight: 100;
  ${overflowWithEllipsis}
`;

ChartSong.Metadata = styled.div`
  grid-column: 8 / span 1;
`;

ChartSong.Metadatum = styled.div`
  flex: 1;
  margin-bottom: ${p => p.theme.space.quarter};
`;
