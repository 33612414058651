import React from 'react';
import {LoadMoreButton} from '@/components';
import {PageGrid, PageGridCenter, EditorialPlacement, SectionTitle} from '@/homePage/components';
import styled from 'styled-components';
import {useRedux} from './Latest.shared';
import {useTranslation} from 'react-i18next';

const Latest = () => {
  const {placements, onPlacementClick, onLoadMoreClick} = useRedux();
  const {t} = useTranslation();
  return (
    <PageGrid>
      <SectionTitle
        title={t('latest.title')}
        subtitle={t('latest.subtitle')}
      />
      {placements.map(placement => (
        <PageGridCenter key={`${placement.schema}:${placement.id}`}>
          <EditorialPlacement placement={placement} onClick={onPlacementClick} />
        </PageGridCenter>
      ))}
      <LoadMoreButton
        path="home.latestSection"
        container={Latest.LoadMore}
        onClick={onLoadMoreClick}
      />
    </PageGrid>
  );
};

export default Latest;

Latest.LoadMore = styled(PageGridCenter)`
  text-align: center;
`;
