import React from 'react';
import {SizedImage} from '@/components';
import styled from 'styled-components';
import {propTypes}  from './ChartReferent.shared';
import {useEntity} from '@/hooks';

const ChartReferent = ({itemId}) => {
  const referent = useEntity('referents', itemId);
  return (
    <ChartReferent.CoverAndTextAndTitle>
      <ChartReferent.Cover>
        <SizedImage
          src={referent.annotatable.imageUrl}
          width={60}
          height={60}
        />
      </ChartReferent.Cover>
      <ChartReferent.TextAndTitle>
        <ChartReferent.Text>{referent.fragment}</ChartReferent.Text>
        <ChartReferent.Title>— {referent.annotatable.linkTitle}</ChartReferent.Title>
      </ChartReferent.TextAndTitle>
    </ChartReferent.CoverAndTextAndTitle>
  );
};

ChartReferent.propTypes = propTypes;

export default React.memo(ChartReferent);

ChartReferent.CoverAndTextAndTitle = styled.div`
  grid-column: 2 / span 7;
  display: flex;
`;

ChartReferent.Cover = styled.div`
  flex-shrink: 0;
  margin-top: 7px;
`;

ChartReferent.TextAndTitle = styled.div`
  margin-left: ${p => p.theme.space.xLarge};
  min-width: 0;
  margin-top: ${p => p.theme.space.quarter};
  margin-bottom: ${p => p.theme.space.quarter};
`;

ChartReferent.Text = styled.span`
  white-space: pre-line;
  background-color: ${p => p.theme.color.background.variant};
  font-weight: normal;
  padding: .2em 0;
  line-height: 1.7em;
  font-size: ${p => p.theme.fontSize.largeReading};
`;

ChartReferent.Title = styled.h3`
  font-weight: 100;
  font-size: ${p => p.theme.fontSize.reading};
  margin-top: ${p => p.theme.space.quarter};
`;
