import React from 'react';
import {SquareButton, TextLabel, LoadMoreButton} from '@/components';
import {PageGrid, PageGridCenter, PageGridFull, SectionTitle, CommunityRanking} from '@/homePage/components';
import styled from 'styled-components';
import {useRedux} from './Community.shared';
import {AppConfig} from '@/config';
import {useTranslation} from 'react-i18next';


const Community = () => {
  const {leaderboard, loggedIn, onRankingClick, onLearnMoreClick, onSignupClick, onGetInvolvedClick} = useRedux();
  const {t} = useTranslation();
  return (
    <PageGrid>
      <SectionTitle
        title={t('community.title')}
        titleSize="largeHeadline"
        subtitle={t('community.subtitle')}
      />
      <PageGridCenter>
        <Community.Buttons>
          {loggedIn ?
            <SquareButton as="a" href={AppConfig.getInvolvedPageUrl} onClick={onGetInvolvedClick}>{t('community.get_involved')}</SquareButton> :
            <SquareButton as="a" href="/signup" onClick={onSignupClick}>{t('community.join')}</SquareButton>
          }
          <SquareButton as="a" href="/contributor_guidelines" onClick={onLearnMoreClick}>{t('community.learn')}</SquareButton>
        </Community.Buttons>
      </PageGridCenter>
      <PageGridFull>
        <Community.ChartHeader>
          <Community.User>
            <TextLabel>{t('community.chart.headers.scholar')}</TextLabel>
          </Community.User>
          <Community.Iq>
            <TextLabel>{t('community.chart.headers.iq')}</TextLabel>
          </Community.Iq>
        </Community.ChartHeader>
        {leaderboard.map(({user: userId, attributionValue: iq}, i) =>
          <CommunityRanking
            key={userId}
            onClick={onRankingClick}
            rank={i + 1}
            iq={iq}
            userId={userId}
          />
        )}
      </PageGridFull>
      <LoadMoreButton path="home.communitySection" container={Community.LoadMore} />
    </PageGrid>
  );
};

export default React.memo(Community);

Community.Buttons = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  a {
    display: block;
  }

  a + a {
    margin-top: ${p => p.theme.space.full};
  }
`;

Community.ChartHeader = styled(PageGrid)`
  padding-bottom: ${p => p.theme.space.quarter};
  border-bottom: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
  align-items: end;
`;

Community.User = styled.div`
  grid-column: 2 / span 3;
`;

Community.Iq = styled.div`
  grid-column: 6 / span 3;
  text-align: right;
`;

Community.LoadMore = styled(PageGridCenter)`
  text-align: center;
`;
