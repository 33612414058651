import React, {useEffect} from 'react';
import {PageHeader, PageFooter, CloseableAd, SolidarityBanner} from '@/components';
import {TopContent, Charts, Videos, Latest, Community} from '@/homePage/components';
import styled, {ThemeProvider} from 'styled-components';
import {useMixpanelEvent} from '@/hooks';
import {zIndex} from '@/style';
import {surfaceTheme} from '@/style/theme';
import {homePageTheme} from '@/style/homePageTheme';

const HomeContent = () => {
  const onLoad = useMixpanelEvent('home:load', {counts_as_page_load: true});
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <ThemeProvider theme={homePageTheme}>
      <PageHeader />
      <SolidarityBanner />
      <HomeContent.AdhesionContainer>
        <CloseableAd name="mobile_home_adhesion" lazy={false} />
      </HomeContent.AdhesionContainer>
      <HomeContent.Section id="featured-stories" noBottomPadding={true}>
        <TopContent />
      </HomeContent.Section>
      <HomeContent.Section id="top-songs">
        <Charts />
      </HomeContent.Section>
      <ThemeProvider theme={surfaceTheme}>
        <HomeContent.Section id="videos">
          <Videos />
        </HomeContent.Section>
      </ThemeProvider>
      <HomeContent.Section noBottomPadding={true} >
        <Latest />
      </HomeContent.Section>
      <HomeContent.Section id="community">
        <Community />
      </HomeContent.Section>
      <PageFooter />
    </ThemeProvider>
  );
};

export default HomeContent;

HomeContent.AdhesionContainer = styled.div`
  ${zIndex('mobileAdhesion')}
  position: sticky;
  top: 0;
`;

HomeContent.Section = styled.div`
  background-color: ${p => p.theme.color.background.main};
  padding: ${p => p.theme.space.xxLarge} 0;
  ${p => p.noBottomPadding ? 'padding-bottom: 0px;' : ''}
`;
