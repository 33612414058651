import React from 'react';
import {SizedImage} from '@/components';
import styled from 'styled-components';
import {overflowWithEllipsis} from '@/style';
import {propTypes} from './ChartAlbum.shared';
import {useEntity} from '@/hooks';

const ChartAlbum = ({itemId}) => {
  const album = useEntity('albums', itemId);
  return (
    <ChartAlbum.CoverAndTitleAndArtist>
      <ChartAlbum.Cover>
        <SizedImage
          src={album.coverArtThumbnailUrl}
          width={60}
          height={60}
        />
      </ChartAlbum.Cover>
      <ChartAlbum.TitleAndArtist>
        <ChartAlbum.Title>{album.name}</ChartAlbum.Title>
        <ChartAlbum.Artist>{album.primaryArtistNames}</ChartAlbum.Artist>
      </ChartAlbum.TitleAndArtist>
    </ChartAlbum.CoverAndTitleAndArtist>
  );
};

ChartAlbum.propTypes = propTypes;

export default React.memo(ChartAlbum);

ChartAlbum.CoverAndTitleAndArtist = styled.div`
  grid-column: 2 / span 7;
  display: flex;
  align-items: center;
`;

ChartAlbum.Cover = styled.div`
  flex-shrink: 0;
`;

ChartAlbum.TitleAndArtist = styled.div`
  margin-left: ${p => p.theme.space.xLarge};
  min-width: 0;
`;

ChartAlbum.Title = styled.h3`
  ${overflowWithEllipsis}
`;

ChartAlbum.Artist = styled.h4`
  font-weight: 100;
  ${overflowWithEllipsis}
`;
